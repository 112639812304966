<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-star-off"></i>&nbsp;
      <span>活动页管理</span>
    </template>
    <div :v-if="isload" class="content">
      <div class="content-header" style="width: 100%;">
        <div style="display: flex;">
          <el-button type="success" class="f-right" @click="addPage()">新增</el-button>
        </div>
        <div class="content-main">
          <el-table class="pdt-list" :data="pages" width="100%" v-loading="loading" element-loading-text="拼命加载中..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-table-column prop="title" label="标题" show-overflow-tooltip width="300" />
            <el-table-column prop="link" label="页面地址" class-name="page-link-cell">
              <template #default="scope">
                <span class="page-link">{{ scope.row.link }}</span>
                <i class="el-icon-document-copy" @click="copyPageLink(scope.$index)" title="复制页面地址"></i>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="80">
              <template #default="scope">
                <label v-if="scope.row.isshow * 1 == 1" style="color: green">已启用</label>
                <label v-else-if="scope.row.isshow * 1 == 0" style="color: red">已停用</label>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200" class-name="cate_control">
              <template #default="scope">
                <el-button v-if="scope.row.isshow == 1" type="text" @click="updatePageStatus(0, scope.$index)"
                  class="delete">停用</el-button>
                <el-button v-else type="text" @click="updatePageStatus(1, scope.$index)">启用</el-button>
                <el-button type="text" @click="addPage(scope.row.pagename)">编辑</el-button>
                <el-button type="text" @click="deletePage(scope.$index)" class="delete">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import pageconst from "@/pageconst";

export default {
  data() {
    return {
      isload: false,
      pages: [],
    };
  },
  methods: {
    saveConfig() {
      // 保存pages.js
      this.axios
        .post(
          pageconst.pagemanage + "?entry=updatepagesconfig",
          JSON.stringify(this.pages),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data == "SUCCESS") {
            console.log("保存成功");
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
    },
    addPage(pagename = "") {
      const jump = this.$router.resolve({
        path: "/page/actdetail",
        query: { pagename: pagename },
      });
      window.open(jump.href, '_blank');
    },
    updatePageStatus(status, index) {
      let that = this;
      ElMessageBox.confirm(
        "您确定要" +
        (status === 0 ? " 禁用 " : status === 1 ? " 启用 " : "") +
        that.pages[index].title +
        " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          that.pages[index].isshow = status;
          that.saveConfig();
        })
        .catch(() => {
          console.log("取消操作");
        });
    },
    deletePage(index) {
      let that = this;
      if (that.pages[index].isshow * 1 == 1) {
        ElMessage.error(that.pages[index].title + " 处于启用状态，无法删除！");
        return false;
      }

      ElMessageBox.confirm(
        "您确定要删除" + that.pages[index].title + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // 删除文件
          that.axios
            .get(
              pageconst.pagemanage +
              "?entry=delpage&pagename=" +
              that.pages[index].pagename,
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then(({ data }) => {
              if (data == "SUCCESS") {
                that.pages.splice(index, 1);
                that.saveConfig();
              }
            });
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    copyPageLink(index) {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.pages[index].link; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例

      ElMessage({
        showClose: true,
        message: "成功复制页面地址",
        type: "success"
      });
    }
  },
  created: function () {
    this.axios
      .get(pageconst.pagemanage + "?entry=getcompages", {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        var { pages } = data;
        this.pages = pages;
        console.log(pages);
        this.isload = true;
      });
  },
};
</script>

<style>
.el-card {
  padding: 0 !important;
}

.el-card__body {
  padding: 10px;
}

.el-card__header {
  padding: 10px !important;
}

.pdt-list {
  margin-top: 20px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep().cate_control {
  text-align: right;
}

:deep().el-table td {
  padding: 8px 0;
}

:deep().el-table .cell {
  max-height: 50px;
}

.cell .el-button {
  margin-right: 15px;
  margin-left: 0;
}

.cell .el-button:last-child {
  margin-right: 0;
}

.cell .el-button.delete {
  color: #f66;
}

.pdt-thumb {
  height: 50px;
  padding: 2px;
  border: 1px solid #ddd;
}

:deep().pdt-thumb img {
  width: auto;
  height: 100%;
}

.readinput,
.sel-btn {
  margin-top: 10px;
}

.sel-btn {
  margin-left: 10px;
}

.dataform:deep().avatar-uploader {
  line-height: 0;
}

.dataform:deep().avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.dataform:deep().avatar {
  width: 100%;
  max-width: 400px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

:deep().page-link-cell .cell {
  display: flex;
  align-items: center;
}

.page-link-cell .cell .page-link {
  width: 0;
  flex-grow: 1;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-link-cell .cell .el-icon-document-copy {
  cursor: pointer;
}
</style>